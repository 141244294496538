import React, { useState } from 'react';
import { UncontrolledCollapse, Button, CardBody, Card, FormGroup, Label, Input, Col, Form } from 'reactstrap';
import localStore from '@faebzz/localstore';

const Tpl = () => {
  const [tpl, setTpl] = useState(0);
  localStore.set('first', 'test');
  const getTpl = () => {
    return tpl*0.9 === Number.isNaN ? '' : Math.round((tpl * 0.9)*10)/10
  }
  return (<div style={{ marginBottom: '1rem', minWidth: '100%' }}>
            <Button color="primary" outline id="tpl" style={{ marginBottom: '1rem', minWidth: '100%' }}>
              10%-Grenze
            </Button>
            <UncontrolledCollapse toggler="#tpl">
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup row>
                      <Label for="weight" sm={4}>Gewicht bei der Geburt in g</Label>
                      <Col sm={8}>
                        <Input type="number" name="weight" id="exampleEmail" onChange={(e) => setTpl(e.target.value)} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="tenpercent" sm={4}>10%-Grenze</Label>
                      <Col sm={8}>
                        <p>{getTpl()}</p>
                      </Col>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>
        )
};

export default Tpl;
