import React from 'react';
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';


const About = () => {
  return (<div style={{ marginBottom: '1rem', minWidth: '100%' }}>
            <Button color="primary" outline id="about" style={{ marginBottom: '1rem', minWidth: '100%' }}>
              Über diesen Rechner
            </Button>
            <UncontrolledCollapse toggler="#about">
              <Card>
                <CardBody>
                  <p>Dieser Rechner wurde entwickelt um das errechnen von Kennzahlen in der Geburtshilfe zu vereinfachen.</p>
                  <p>Der errechnete Geburtstermin wird nach der Naegele-Regel errechnet.</p>
                  <p>Dieser Rechner wurde von  <a href="https://github.com/faebzz">Fabian Schaper</a> entwickelt.</p>
                  <p>Dieser Rechner wird von <a href="https://hebammehoch2.ch/">Hebamme&sup2;</a> zur Verfügung gestellt.</p>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>
        )
};

export default About;
