import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGb from 'date-fns/locale/en-GB';
import moment from 'moment';
import { UncontrolledCollapse, Button, CardBody, Card, FormGroup, Label, Col, Form } from 'reactstrap';
registerLocale('en-GB', enGb);


const Ssw = () => {
  const [dlp, setDlp] = useState(moment().toDate());
  const [cd, setCd] = useState(moment().toDate());
  const getSsw = () => {
    let totalDays = Math.floor(280 - moment(dlp).diff(moment(cd), 'days', true));
    let weeks = Math.floor(totalDays/7);
    let day = totalDays - (weeks*7)
    // if(moment(new Date()).isoWeekday() === 1 && moment(dlp).isoWeekday() === 1) correction = -6;
    // let day = 7 - wd;
    return `${weeks} ${day}/7`;
  }
  return (<div style={{ marginBottom: '1rem', minWidth: '100%' }}>
            <Button color="primary" outline id="ssw" style={{ marginBottom: '1rem', minWidth: '100%' }}>
              Schwangerschaftswoche
            </Button>
            <UncontrolledCollapse toggler="#ssw">
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup row>
                      <Label for="exampleEmail" sm={4}>Geburtstermin</Label>
                      <Col sm={8}>
                      <DatePicker
                        showPopperArrow={false}
                        selected={dlp}
                        onChange={date => setDlp(date)}
                        dateFormat="dd.MM.yyyy"
                        locale={enGb}
                        withPortal
                        onChangeRaw={(e) => e.preventDefault()}
                        onKeyDown={(e) => e.preventDefault()}
                        onFocus={(e) => e.preventDefault()}
                        locale="en-GB"
                      />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="exampleEmail" sm={4}>Datum</Label>
                      <Col sm={8}>
                      <DatePicker
                        showPopperArrow={false}
                        selected={cd}
                        onChange={date => setCd(date)}
                        dateFormat="dd.MM.yyyy"
                        locale={enGb}
                        withPortal
                        onChangeRaw={(e) => e.preventDefault()}
                        onKeyDown={(e) => e.preventDefault()}
                        onFocus={(e) => e.preventDefault()}
                      />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="exampleEmail" sm={4}>Errechnete Schwangerschaftswoche</Label>
                      <Col sm={8}>
                        <p>{getSsw()}</p>
                      </Col>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>
        )
};

export default Ssw;
