import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Ssw from './ssw/Ssw.js'
import Gt from './gt/Gt.js';
import Bmi from './bmi/Bmi.js';
import Tpl from './tpl/Tpl.js';
import About from './about/About.js';
import './App.css';

function App() {
  return (
    <div className="App">
      <Container>
        <Row style={{textAlign: 'center !important'}}>
          <Col>
            <h3 style={{textAlign: 'center'}} className={'title'}>Rechner</h3>
          </Col>
        </Row>
        <Row>
          <Gt />
        </Row>
        <Row>
          <Ssw />
        </Row>
        <Row>
          <Bmi />
        </Row>
        <Row>
          <Tpl />
        </Row>
        <Row>
          <About />
        </Row>
      </Container>
    </div>
  );
}

export default App;
