import React, { useState } from 'react';
import { UncontrolledCollapse, Button, CardBody, Card, FormGroup, Label, Input, Col, Form } from 'reactstrap';


const Bmi = () => {
  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);
  const getBmi = () => {
    const bmi = Math.round((weight/Math.pow((height/100), 2)*10))/10;
    if(Number.isNaN(bmi) || bmi === Infinity) return '-';
    return bmi;
  }
  return (<div style={{ marginBottom: '1rem', minWidth: '100%' }}>
            <Button color="primary" outline id="bmi" style={{ marginBottom: '1rem', minWidth: '100%' }}>
              BMI
            </Button>
            <UncontrolledCollapse toggler="#bmi">
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup row>
                      <Label for="weight" sm={4}>Gewicht in Kg</Label>
                      <Col sm={8}>
                        <Input type="number" name="weight" id="weight" onChange={(e) => setWeight(e.target.value)} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="height" sm={4}>Grösse in cm</Label>
                      <Col sm={8}>
                        <Input type="number" name="height" id="height" onChange={(e) => setHeight(e.target.value)} />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="exampleEmail" sm={4}>BMI</Label>
                      <Col sm={8}>
                        <p>{getBmi()}</p>
                      </Col>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>
        )
};

export default Bmi;
