import React, { useState } from 'react';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGb from 'date-fns/locale/en-GB';
import { UncontrolledCollapse, Button, CardBody, Card, FormGroup, Label, Col, Form } from 'reactstrap';
registerLocale('en-GB', enGb);


const Gt = () => {

  const [dlp, setDlp] = useState(new Date());
  return (<div style={{ marginBottom: '1rem', minWidth: '100%' }}>
            <Button color="primary" outline id="et" style={{ marginBottom: '1rem', minWidth: '100%' }}>
              Errechneter Termin
            </Button>
            <UncontrolledCollapse toggler="#et">
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup row>
                      <Label for="exampleEmail" sm={4}>1. Tag der letzten Periode</Label>
                      <Col sm={8}>
                        <DatePicker
                          showPopperArrow={false}
                          selected={dlp}
                          onChange={date => setDlp(date)}
                          dateFormat="dd.MM.yyyy"
                          locale={enGb}
                          withPortal
                          onChangeRaw={(e) => e.preventDefault()}
                          onKeyDown={(e) => e.preventDefault()}
                          onFocus={(e) => e.preventDefault()}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="exampleEmail" sm={4}>Errechneter Geburtstermin</Label>
                      <Col sm={8}>
                        <p>{moment(dlp).add(7, 'd', true).add(-3, 'months').add(1, 'y', true).format('DD.MM.YYYY')}</p>
                      </Col>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </UncontrolledCollapse>
          </div>
        )
};

export default Gt;
